
.footer{
    background: white;
    padding: 15px 0 0;
    font-weight: 700;

    p {
        font-size: x-small;
        font-weight: 400;
    }

    .float-end{
        font-weight: 300;
    }
}