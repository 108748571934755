@import "/src/_colors.scss";

.top_headline {
  background: $c-red !important;
  padding-top: 15px;
  color: white;

  .title {
    font-size: 40px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: -1px;
    line-height: 1;
    margin-bottom: 25px;
  }
}

.content_wrap {
  background: $c-grey-bg;
  flex: 1 0 auto;
}

.page_container_wrap{
  padding: 30px 0 50px 0;
}

.page_container_wrap_white {
  background: white;
}
