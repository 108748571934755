@import "/src/_colors.scss";


.header {
  background: $c-grey-light;

  @media only screen and (max-width: 991px) {
    img.logo {
      width: 170px;
    }
  }

  @media only screen and (max-width: 560px) {
    img.logo {
      width: 140px;
    }
  }

  .nav_top_line {
    .navbar-text {
      color: black;
      font-size: 13px;

      img {
        vertical-align: bottom;
      }
    }
  }

  .nav_menu_user {

    svg {
      margin-top: -3px;
    }

    a {
      text-transform: uppercase;
      font-weight: bold;
      color: $c-red;
      font-size: 12px;
    }

    .btn-logout {
      border-radius: 35px;
      border: 1px solid $c-red;
      background: white;
      color: $c-red;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
      padding: 10px 20px;
      box-shadow: 4px 2px 10px 0px rgba(0, 0, 0, 0.2);
    }
  }

  .navbar-toggler {
    border-color: $c-red;
  }

  .navbar-collapse {
    overflow: hidden;

    .nav-link {
      color: $c-red !important;
      padding: 15px 30px !important;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 900;


      &.active {
        box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.2);
        background: $c-red;
        color: white !important;
      }
    }
  }
}



