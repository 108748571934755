@import "/src/_colors.scss";

.collapseBtnWrap {
  position: absolute;
  bottom: 10px;
  text-align: center;
  width: 100%;
  z-index: 9;

  .collapseBtn {
    border: 3px solid $c-grey-bg;
    border-radius: 200px;
    height: 40px;
    width: 40px;

    svg {
      margin-top: -4px;
      margin-left: -3px;
    }
  }
}

.collapseWrap {
  position: relative;
  padding-bottom: 30px;
}

.collapseContent {
  //padding-bottom: 30px;
}