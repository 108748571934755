@import "/src/_colors.scss";

.breadcrumb {

  padding: .75rem 0;
  margin-bottom: 10px;

  .breadcrumb-item  {
    font-weight: 900 !important;
    font-size: 13px;
    color: white !important;
    &::before{
      color: white;
    }
    a{
      color: white;
    }

  }
}