@import "_colors.scss";

/* make the customizations */
$primary: $c-red;
$link-decoration: none;
$link-hover-decoration: underline;
$table-group-separator-color: #dee2e6;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

.form-control {
  height: calc(1.5em + 0.75rem + 2px);
}

label {
  position: relative;
}

.navbar-text {
  display: inline-block;
}

.btn-reset-pass {
  position: absolute;
  top: 15px;
  right: 15px;
}

.container-full-width {
  margin-left: calc(50% - 50vw + 10px);
  margin-right: calc(50% - 50vw + 10px);

  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .form-label.text-end {
    text-align: left !important;
  }
}
