@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;800;900&family=Righteous&display=swap');

/*font-family: 'Righteous', cursive;*/

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  font-family: 'Montserrat', sans-serif;
  /*font-family: 'Righteous', cursive;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-datepicker-popper{
  z-index: 1115!important;
}
.rbc-overlay{
  z-index: 1115!important;
}
.rbc-date-cell a[href="#"]{
  cursor: default;
}

.rbc-day-slot .rbc-event:hover{
    width: 100%!important;
    z-index: 999;
}
.dropdown-menu{
  z-index: 1100!important;
}