.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.rct-icons-fa5 {
  .rct-icon-check,
  .rct-icon-uncheck,
  .rct-icon-half-check,
  .rct-icon-expand-open,
  .rct-icon-expand-close,
  .rct-icon-expand-all,
  .rct-icon-collapse-all {
    position: relative;
    top: 2px;
  }
  .rct-icon-expand-close {
    width: 8px;
  }
  .rct-icon-expand-open {
    width: 10px;
  }
  .rct-icon-expand-all,
  .rct-icon-collapse-all {
    width: 14px;
  }
}

.rct-node-icon:has(.rct-icon-parent-open),
.rct-node-icon:has(.rct-icon-parent-close),
.rct-node-icon:has(.rct-icon-leaf) {
  display: none;
}

span.rct-collapse {
  padding: 2px;
}

.treelist-container .rct-collapse.rct-collapse-btn {
  position: relative;
  align-self: baseline;
  top: 4px;
}

.btn-clear-input-search {
  border: 0;
  background: none;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: larger;
}
