@import "/src/_colors.scss";

$size: 80px;
.stat_oval {
  text-align: center;
  line-height: 1.2;
  position: relative;
  margin-top: 45px;

  &::before {
    //left: 0;
    //right: 0;
    top: -45px;
    content: " ";
    display: block;
    position: absolute;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-top-left-radius: $size * 2;
    border-top-right-radius: $size * 2;
    border-bottom: 0;
    height: $size;
    width: 100%;
    max-width: 170px;
    left: 50%;
    transform: translateX(-50%);
  }

  .stat_num {
    font-size: 26px;
    color: $c-red;
    font-weight: 900;
  }

  .stat_text {
    font-size: 20px;
    //text-transform: uppercase;
  }
}
// New styles --------------------------------------
.col-semi-round-stats {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  //height: 100%;
  border-right: 1px solid #d9d9d9;
  padding-right: 20px;
}

.card-body-inner {
  display: flex;
  flex-direction: row;
}

.canvas-wrap {
  width: 140px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .stat-text {
    font-size: 1rem;
    text-align: center;
    position: relative;
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px ) {
  .card-body-inner {
    flex-direction: column-reverse;

    .col-sm-8 {
      width: 100%;
    }

    .col-semi-round-stats {
      flex-direction: row;
      align-items: baseline;
      padding-right: 0;
      border-right: 0;
      border-top: 1px solid #d9d9d9;
      padding-top: 20px;
      margin-top: 20px;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 576px) {
  .card-body-inner {
    flex-direction: column-reverse;
  }

  .col-semi-round-stats {
    flex-direction: row;
    padding-right: 0;
    border-right: 0;
    border-top: 1px solid #d9d9d9;
    padding-top: 20px;
    margin-top: 20px;
  }

  .canvas-wrap {
    width: 120px;
  }
}

/* Tooltip */
.easy-tooltip {
  position: relative;

  .tooltip-text {
    visibility: hidden;
    width: 100%;
    background-color: #f5f5f5;
    color: #000;
    text-align: center;
    font-weight: 400;
    padding: 5px;
    border-radius: 2px;
    box-shadow: rgb(0 0 0 / 25%) 0 1px 2px;

    position: absolute;
    z-index: 1;
    bottom: 95%;
    left: 50%;
    transform: translate(-50%, 0);

    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #f5f5f5 transparent transparent transparent;
  }
}
.easy-tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}