@import "/src/_colors.scss";

.anglesWrap {
  width: 100%;
  position: absolute;
  text-align: center;
  color: $c-red;
  //font-size: 14px;
}

.angleIcon {
  position: absolute;
  transform: translate(-50%, -50%);
}

.angleRight {
  top: -90px;
  left: 47%;
}

.angleDown {
  right: 22%;
  top: -12px;
}

.angleLeft {
  bottom: -80px;
  left: 47%;
}

.angleUp {
  top: -12px;
  left: 22%;
}


.whiteLine {
  color: $c-grey-dark-text;
  font-size: 20px;
  font-weight: 800;
  padding-top: 20px;
  padding-bottom: 20px;

  svg{
    font-size: 35px;
    margin-right: 10px;
  }

}

.filters-box-home {
  display: flex;
  flex-direction: row
}

@media only screen and (max-width: 768px ) {
  .filters-box-home {
    flex-direction: column;
  }
}

// Contracts List and Stats

.contracts-stats {
  .col-xl-6 {
    display: flex;
    flex-direction: column;

    .pb-4 {
      height: 100%;
    }
  }

  .table-responsive-md {
    border-bottom: 1px solid #dfdfdf !important;

    .table {
      border: none !important;

      th:nth-child(3), td:nth-child(3) {
        text-align: right;
      }
    }
  }
}

.shadow-sm.card {
  box-shadow: none !important;
}
