.timeline-horz-card-wrapper {
  outline: none !important;
}

.timeline-circle.horizontal.active.active::after {
  background: #ffffff;
}

.timeline-card-content {
  .card-title {
    margin-bottom: 0.5rem;

    a {
      font-weight: 400
    }
  }
  .card-sub-title {
    color: #757575;
  }
}

section.timeline-card-content.active {
  box-shadow: 0 0 5px 1px var(--bs-primary);
  filter: none;
}