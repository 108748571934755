@import "/src/_colors.scss";

.modal {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 999;
  padding: 40px 20px 20px;
}

.modal-content-wrapper {
  max-width: 520px;
  width: 80vw;
  background: $c-grey-bg;
  overflow: auto;
}

.close-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.close-btn {
  font-size: 1.5rem;
  padding: 0.5rem;
  background: transparent;
  width: 48px;
  height: 48px;
  display: block;
  border: none;
  outline: none;
}

.modal-content {
  padding: 0 2rem 1rem;
}
