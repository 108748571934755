@import "/src/_colors.scss";

.add-button-wrapper {
  margin-top: 1rem;
}

.button-text {
  color: $c-red;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: #9f2f2f;
  }
}
