@import "/src/_colors.scss";
@import "/src/_custom.scss";

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  font-size: 14px;
  //background: $c-grey-bg;

}

.card_link {
  margin-top: auto;
  text-transform: uppercase;
  font-weight: 900;

  font-size: 12px;

  svg {
    margin-top: -3px;
    margin-right: 10px;
  }
}

.pl-1 {
  padding-left: 1rem;
}

.row-end {
  display: flex;
  justify-content: flex-end;
  padding: 0 0 10px 0;
}
