@import "/src/_colors.scss";

.box_title_grey {
  color: $c-grey-dark-text;
  font-size: 30px;
  font-weight: 900;
}

.box_title_red {
  font-size: 18px;
  color: $c-red;
  font-weight: 800;
  text-space: 1px;
  letter-spacing: -1px;
}

.chevron_after {
  position: absolute;
  right: -27px;
  top: 25%;
  font-size: 30px;
  color: $c-grey-dark-text;
}

.chevron_bg {
  position: absolute;
  right: 0px;
  bottom: 50px;
  font-size: 150px;
  color: $c-grey-bg
}

// Tables global

.table-responsive-md,
.table-responsive {
  border-bottom: 1px solid #dfdfdf !important;

  .table {
    border: none !important;

    th {
      background-color: #c3c3c3;
      color: #353535;
      padding: 10px;
      border-color: white !important;
      border-width: 0 5px !important;

      td {
        padding: 10px;
        border-color: white !important;
        border-width: 0 5px !important;
        /* border-bottom: 2px solid #fff !important; */
      }
    }

    tr {
      border: none !important;

      td {
        padding: 8px 10px;
        border-color: white !important;
        border-width: 0 5px !important;
        border-bottom: 1px solid #d2d2d2;
      }
    }

    tr.winner > td {
      background-color: #cbf1cb;
    }

    td:first-child, th:first-child, td:last-child, th:last-child {
      border: none;
    }
  }
}

// Table Qualitative Analysis

.table-responsive {
  max-height: 90vh;

  .qa-table-responsive {
    text-align: left;

    thead {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: #fff;

      tr {
        border-top: none;

        th:first-child {
          text-align: left;
          min-width: 150px;
          position: sticky;
          left: 0;
          //background: #fff;
          border-left: none;
        }
        th:nth-child(14),
        th:nth-child(23),
        th:nth-child(24),
        th:nth-child(25),
        th:nth-child(26) {
          text-align: right;
        }
      }
    }

    tbody {
      tr {
        vertical-align: middle;

        td {
          span {
            //text-wrap: nowrap;
          }

          .stars-wrapper {
            display: flex;
            padding-top: 5px

            //svg {
            //  color: #c73b3b;
            //}
          }
        }

        td:first-child {
          text-align: left;
          position: sticky;
          left: 0;
          border-left: none;
          background-color: #fff !important;
          color: $c-red;
          font-weight: 500;
        }
      }

      tr:nth-child(odd) td {
        background: #fff;
        --bs-table-accent-bg: rgb(0 0 0 / 15%);
      }

      tr:nth-child(even) td {
        background: #fff;
      }

      .table-col-large-width {
        min-width: 260px;
        max-width: 320px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .table-col-small-width {
        min-width: 130px;
      }

      .text-align-right {
        text-align: right;
      }

      svg {
        //background-color: #c73b3b30 !important;
        //text-align: center;
        font-size: medium;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .flex-order .row .col {
    flex: auto;
    width: auto;
  }
}

@media only screen and (max-width: 600px) {
  .flex-order .row .col {
    width: 100%;
    max-width: 100% !important;
  }
}

//.qa-table-responsive > tbody > tr:nth-child(odd):hover td,
//.qa-table-responsive > tbody > tr:nth-child(odd):hover td:first-child {
//  background: #0000000f !important;
//}

//.qa-table-responsive > thead > tr > th:nth-child(2) {
//  min-width: 80px;
//  position: sticky;
//  left: 150px;
//  background: #fff;
//  border-right: none;
//}
//.qa-table-responsive > tbody > tr > td:nth-child(2) {
//  position: sticky;
//  left: 150px;
//  border-right: none;
//  background-color: #fff !important;
//}
