@import "/src/_colors.scss";

.calendar_icon {
  position: absolute;
  right: 25px;
  top: 10px;
}

.search_icon {
  position: absolute;
  right: 25px;
  top: 5px;
  z-index: 10;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.relative {
  position: relative;
}

.react-datepicker__day--selected {
  background-color: $c-red !important;
}

.form-control {
  padding: 0px 40px 0px 20px;
  font-size: 14px;
  //border: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(black, 0.075);
}

.select_cart_wrap {
  background: transparent;
  border: 0;
  bottom: 0;
  // padding: 4px 25px 0 0;
  position: absolute;
  right: 25px;
  top: 4px;
  cursor: pointer;

  svg {
  }
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  background-position: right -15px center;
  background-repeat: no-repeat;
  background-size: 9px;
  background-origin: content-box;
  $color-rgb: "rgb(" + red($c-red) + "%2C" + green($c-red) + "%2C" +
    blue($c-red) + ")";
  //$color-rgb:  str-replace($c-red, '#', '%23');
  background-image: url('data:image/svg+xml;charset=utf-8, \
    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" class="svg-inline--fa fa-angle-down fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#{$color-rgb}" d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path></svg>');
}

.multiple-select {
  .form-control[disabled] {
    background-color: #e9ecef;
    opacity: 1;
  }
  .rbt-input {
    min-height: 37px;
    height: fit-content;
    max-height: 120px;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .rbt-input-wrapper {
    display: flex;
    flex-wrap: wrap;
    min-height: 35px;
    padding: 3px 6px 3px 0;
    align-items: center;
    gap: 4px;
  }

  .rbt-input-main {
    min-height: 25px;
  }

  .rbt-token-removeable {
    pointer-events: painted;
    min-width: 15%;
    max-width: 100%;
    min-height: 25px;
    //max-height: 25px;
    //white-space: nowrap;
    //text-overflow: ellipsis;
    //overflow: hidden;
    hyphens: auto;
    padding: 4px 21px 4px 7px;
    background-color: #f9ebeb;
    color: $c-red;
    border-radius: 4px;
    display: inline-block;
    font-size: 15px;
    line-height: 1.2em;
    cursor: pointer;
    position: relative;

    &:focus,
    &:visited {
      background-color: $c-red;
      color: white;

      button {
        color: white;
      }
    }

    @media (min-width: 1200px) {
      max-width: 48%;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 20px;
      height: 20px;
      border: none;
      background-color: transparent;
      color: $c-red;
      font-size: 16px;
      font-weight: 500;
      right: 2px;
      top: 0;
      bottom: 4px;
      margin: auto 0;
    }
  }
}

.rbt.has-aux {
  position: relative;

  .rbt-aux {
    position: absolute;
    top: 1px;
    right: 40px;
  }

  button.close.rbt-close {
    border: none;
    background: #fff;

    span {
      color: #dc3545;
      font-size: 2em;
      line-height: 1em;
    }
  }
}

#btn-cpv {
  border-color: var(--bs-border-color);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  color: var(--bs-primary);
}
#btn-cpv:hover {
  color: var(--bs-btn-hover-color);
}

.btn.dropdown-toggle.btn-light {
  font-size: 14px;
  --bs-btn-bg: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: var(--bs-border-radius-sm);
  border: var(--bs-border-width) solid var(--bs-border-color);
}
.dropdown-menu.show.CheckboxMenu {
  right: 0 !important;
  left: auto !important;
  transform: translate(-10px, 40px) !important;
}